/** @jsxImportSource @emotion/react */
import {
  Box,
  Collapse,
  inputBaseClasses,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { animated, useSpring } from "react-spring";

type TextFieldWithAnimatedLineProps = { helperText: string } & TextFieldProps;

const TextFieldWithAnimatedLine: React.FC<TextFieldWithAnimatedLineProps> = ({
  helperText,
  error,
  onFocus,
  onBlurCapture,
  ...props
}) => {
  const theme = useTheme();
  const [isFocused, setFocused] = useState(false);

  const width = 1000;
  const radius = 200;
  const height = 30;
  const stroke = isFocused ? 1.5 : 1.5;
  const lineColor = isFocused ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.2)";
  const y = 20;
  const divisions = 5;

  const [focus, api] = useSpring(() => ({ x: -radius }));

  function gravity(x: number, fx: number) {
    let energy = (radius - Math.abs(x - fx)) / radius;
    if (energy < 0) return 0;

    energy = Math.sin(Math.sin(energy));

    //console.log(x, fx, "-->", energy);
    return energy * y;
  }

  const transPath = (fx: number) => {
    const delta = width / divisions;

    let p1 = { x: 0, y };
    let p2;

    let path = "";
    for (let i = 0; i <= divisions; i++) {
      const x = i * delta;

      /// bottom --> up
      //p2 = { x: x, y: height - stroke - gravity(x, fx) };
      p2 = { x: x, y: height - stroke + gravity(x, fx) };

      /// top --> down
      //p2 = { x: x, y: stroke + gravity(x, fx) };

      if (i === 0) {
        p1 = p2;
        path = "M " + p1.x + " " + p1.y;
      }

      var xc = (p1.x + p2.x) / 2;
      var yc = (p1.y + p2.y) / 2;

      //path += "L " + p1.x + " " + p1.y;
      path += " Q " + p1.x + " " + p1.y + " " + xc + " " + yc;
      p1 = p2;
    }
    return path;
  };

  return (
    <Box
      sx={{
        maxWidth: { md: "min(800px, 90%)", xs: "100%" },
        isolation: "isolate",
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <TextField
          sx={{
            [`& .${inputBaseClasses.root}`]: {
              // backgroundColor: "rgba(0, 0, 0, .06)",
              "&::before": { borderBottomWidth: "0px !important" },
              "&::after": { borderBottomWidth: "0px !important" },
            },
          }}
          // onBlur={() => setFocused(false)}
          onBlurCapture={(e) => {
            setFocused(false);
            onBlurCapture?.(e);
          }}
          onFocus={(e) => {
            setFocused(true);
            api.start({
              from: { x: -radius },
              to: { x: width + radius },
              config: { duration: 700 },
            });
            onFocus?.(e);
          }}
          {...props}
        />

        <div
          css={{
            pointerEvents: "none",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            // overflow: "hidden",
          }}
        >
          <svg width="100%" height="200%">
            <animated.path
              d={focus.x.to(transPath)}
              fill="none"
              stroke={lineColor}
              strokeWidth={stroke}
              style={{
                transition: theme.transitions.create("stroke"),
              }}
            />
          </svg>
        </div>
      </Box>

      <Box sx={{ height: "32px" }}>
        <Collapse in={error}>
          <Typography
            sx={{ fontSize: "12px", color: "red", lineHeight: "16px" }}
            children={helperText}
          />
        </Collapse>
      </Box>
    </Box>
  );
};

export default TextFieldWithAnimatedLine;
